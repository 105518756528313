function gsapScrollImage() {
    $(document).ready(function () {
        gsap.registerPlugin(ScrollTrigger);
        ScrollTrigger.saveStyles(".mobile, .desktop");
    
        if(document.querySelector('.img-house')) {
            ScrollTrigger.matchMedia({"(min-width: 740px)": function() {                  
                let hI = $('.img-house').height() + 72;
                ScrollTrigger.create({
                    trigger: '.cont-scroll-i',
                    start: "top top+=80px",
                    pin: '.img-house',
                    end: "bottom " + hI + "px",
                });
            }});
        }
    });
}

function gsapScrollCard() {
    $(document).ready(function () {
        gsap.registerPlugin(ScrollTrigger);
        ScrollTrigger.saveStyles(".mobile, .desktop");
    
        if(document.querySelector('.card-risparmio')) {
            ScrollTrigger.matchMedia({"(min-width: 740px)": function() {      
                let hC = $('.card-risparmio').height() + 190;
                ScrollTrigger.create({
                    trigger: '.cont-scroll-r',
                    start: "top top+=80px",
                    pin: '.card-risparmio',
                    end: "bottom " + hC + "px",
                });
            }});
        }
    });
}